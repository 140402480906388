<script lang="ts" setup></script>
<template>
  <div class="dropdown-menu-item"><slot /></div>
</template>
<style lang="scss" scoped>
.dropdown-menu-item {
  padding: 16px 16px 16px 16px;
  margin: 0px;
  font-weight: 500;
  &:hover {
    transition-duration: 0.4s;
    background-color: #f5f5f6;
  }
}
</style>
